<script lang="ts">
	import {
		createDefaultSettings,
		createDefaultStats,
		createLetterStates,
		createNewGame,
		getWordNumber,
		modeData,
		ROWS,
		seededRandomInt,
		words,
	} from "./utils";
	import Game from "./components/Game.svelte";
	import {letterStates, mode, settings} from "./stores";
	import {GameMode} from "./enums";
	import {Toaster} from "./components/widgets";
	import {setContext} from "svelte";
	import {db, generateAuth} from "./firebase";

	import {
		addDoc,
		arrayUnion,
		collection,
		doc as makeDoc,
		getDocs,
		limit,
		orderBy,
		query,
		Timestamp,
		updateDoc,
		where
	} from "firebase/firestore";
	import {getAuth, signInAnonymously} from "firebase/auth";

	export let version: string;
	setContext("version", version);
	localStorage.setItem("version", version);

	let stats: Stats;
	let word: string;
	let state: GameState;

	settings.set(
		(JSON.parse(localStorage.getItem("settings")) as Settings) || createDefaultSettings()
	);
	settings.subscribe((s) => localStorage.setItem("settings", JSON.stringify(s)));

	const hash = window.location.hash.slice(1).split("/");
	const modeVal: GameMode = !isNaN(GameMode[hash[0]])
		? GameMode[hash[0]]
		: parseInt(localStorage.getItem("mode")) || modeData.default;
	mode.set(modeVal);
	// If this is a link to a specific word make sure that that is the word
	if (!isNaN(parseInt(hash[1])) && parseInt(hash[1]) < getWordNumber(modeVal)) {
		modeData.modes[modeVal].seed =
			(parseInt(hash[1]) - 1) * modeData.modes[modeVal].unit + modeData.modes[modeVal].start;
		modeData.modes[modeVal].historical = true;
	}
	mode.subscribe((m) => {
		localStorage.setItem("mode", `${m}`);
		window.location.hash = GameMode[m];
		stats = (JSON.parse(localStorage.getItem(`stats-${m}`)) as Stats) || createDefaultStats(m);
		let temp: GameState;
		if (modeData.modes[m].historical === true) {
			temp = JSON.parse(localStorage.getItem(`state-${m}-h`));
			if (!temp || temp.wordNumber !== getWordNumber(m)) {
				state = createNewGame(m);
			} else {
				state = temp;
			}
		} else {
			temp = JSON.parse(localStorage.getItem(`state-${m}`));
			if (!temp || modeData.modes[m].seed - temp.time >= modeData.modes[m].unit) {
				state = createNewGame(m);
			} else {
				// This is for backwards compatibility, can be removed in a day
				if (!temp.wordNumber) {
					temp.wordNumber = getWordNumber(m);
				}
				state = temp;
			}
		}
		// Set the letter states when data for a new game mode is loaded so the keyboard is correct
		const letters = createLetterStates();
		for (let row = 0; row < ROWS; ++row) {
			for (let col = 0; col < state.board.words[row].length; ++col) {
				if (
					letters[state.board.words[row][col]] === "🔳" ||
					state.board.state[row][col] === "🟩"
				) {
					letters[state.board.words[row][col]] = state.board.state[row][col];
				}
			}
		}
		letterStates.set(letters);
	});

	$: saveState(state);
	function saveState(state: GameState) {
		if (modeData.modes[$mode].historical) {
			localStorage.setItem(`state-${$mode}-h`, JSON.stringify(state));
		} else {
			localStorage.setItem(`state-${$mode}`, JSON.stringify(state));
		}
	}
	let toaster: Toaster;

	let inputElement;
	let editStatus = false;
	let currentId = "";

	export const getWord = async () => {
		/* Cases:
			1. No word date
			2. wordDate = today
			3. wordDate = yesterday
			4. wordDate < yesterday
		 */

		if (!$settings.groupDoc?.currentDate) {
			await addRandomWord();
			return;
		}

		const currentDate = new Timestamp($settings.groupDoc?.currentDate.seconds, $settings.groupDoc?.currentDate.nanoseconds).toDate();

		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

		let yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);

		// Case 1 and 4
		if (currentDate < yesterday) {
			// Create a new word at random
			await addRandomWord();
			return;
		}

		if (currentDate >= today) {
			// Pull today's word if it's present
			if ($settings.groupDoc.currentWord) {
				word = $settings.groupDoc.currentWord;
				return;
			}
		}

		// Normal case:
		// Query yesterday's scores for this group, find the best score, find their submitted word
		const q = query(collection(db, "groups"), where("groupCode", "==", $settings.groupDoc?.groupCode));

		const querySnapshot = await getDocs(q);

		if (querySnapshot.empty) {
			// return a new word just so they can play
		}
		else {
			// forEach, but expect only one result
			querySnapshot.forEach((doc) => {
				const groupDoc = {
					id: doc.id
				}
				return getSubmittedWord(groupDoc);
			});
		}
	}

	const getSubmittedWord = async (groupDoc) => {
		// Find the best score, with hard mode breaking ties, sorted by first submission
		const q = query(collection(db, "scores"),
						where("word_id", "==", $settings.groupDoc.currentWordId),
						orderBy("score"), orderBy("hardMode", "desc"), orderBy("submissionTime"),
						limit(1));

		const querySnapshot = await getDocs(q);

		if (querySnapshot.empty) {
			// No one submitted a word, so just pick a random word
			return await addRandomWord();
		}
		else {
			// forEach, but expect only one result
			querySnapshot.forEach((doc) => {
				const winningScore = doc.data();
				const docToAdd = {
					word: winningScore.nextWord,
					submittedUser: winningScore.isRandom ? "Random Wordle" : winningScore.username,
					date: Timestamp.fromDate(new Date()),
					groupCode: $settings.groupDoc.groupCode,
				};
				addDoc(collection(db, "words"), docToAdd).then((innerDoc) => {
					console.log(innerDoc);
					$settings.wordDoc = {
						id: innerDoc.id,
						word: docToAdd.word,
						submittedUser: docToAdd.submittedUser,
						date: docToAdd.date,
						groupCode: $settings.groupDoc.groupCode
					};

					updateDoc(makeDoc(db, "groups", groupDoc.id), {
						currentDate: docToAdd.date,
						currentWord: docToAdd.word,
						currentWordId: innerDoc.id,
						words: arrayUnion(innerDoc.id)
					});
					word = docToAdd.word;
				});
			});
		}
	}

	const addRandomWord = async () => {
		const docToAdd =  {
			word: words.words[seededRandomInt(0, words.words.length, Math.random())],
			submittedUser: "Random Wordle",
			date: Timestamp.fromDate(new Date()),
			groupCode: $settings.groupDoc.groupCode,
		};
		await addDoc(collection(db, "words"), docToAdd).then((doc) => {
			console.log(doc);
			$settings.wordDoc = {
				id: doc.id,
				word: docToAdd.word,
				submittedUser: docToAdd.submittedUser,
				date: docToAdd.date,
				groupCode: $settings.groupDoc.groupCode
			};
		});
		word = docToAdd.word;
	}

	const reloadGroup = () => {
		state = createNewGame(GameMode.daily);
		getWord();
	}

	getWord();
</script>
{#await generateAuth()}
	<h3>Loading...</h3>
{:then val}
	<Toaster bind:this={toaster} />
	{#if toaster}
		<Game {stats} {word} {toaster} bind:game={state} on:reloadGroup={reloadGroup} />
	{/if}
{:catch e}
	<h3>Tech demo issues.. please refresh</h3>
{/await}
