<script lang="ts">
  import {collection, getDocs, getDoc, query, where, addDoc} from "firebase/firestore";

  import { db } from "../../firebase";
  import type Toaster from "./Toaster.svelte";
  import {createEventDispatcher, getContext, onMount} from "svelte";
  import {settings} from "../../stores";
  import Input from "../settings/Input.svelte";

  const dispatch = createEventDispatcher();

  const toaster = getContext<Toaster>("toaster");

  export let visible: boolean;
  export let groupCode: string;

  let showCreateGroup = false;
  let showJoinGroup = false;

  let msg = "";
  let searchGroupCode = "";
  let searchedGroup: GroupDocument = null;

  const restart = () => {
    groupsExceptThisOne = $settings.joinedGroups.filter((el) => el !== searchGroupCode);
    msg = "";
    searchGroupCode = "";
    showCreateGroup = false;
    showJoinGroup = false;
    groupCode = "";
  }

  const searchForGroup = async () => {
    const q = query(collection(db, "groups"), where("groupCode", "==", groupCode.toUpperCase()));

    if (groupCode.toUpperCase() === $settings.groupDoc.groupCode) {
      toaster.pop("You're already in that group", 3);
      return;
    }

    searchGroupCode = groupCode.toUpperCase();

    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    if (querySnapshot.empty) {
      msg = "No group found.";
      showCreateGroup = true;
      showJoinGroup = false;
    }
    else {
      // forEach, but expect only one result
      querySnapshot.forEach((doc) => {
        const groupData = doc.data();

        searchedGroup = {
          id: doc.id,
          groupCode: groupData.groupCode,
          currentDate: groupData.currentDate,
          currentWord: groupData.currentWord,
          currentWordId: groupData.currentWordId,
          words: groupData.words
        }
        msg = `Group "${searchGroupCode}" found!`;
        showJoinGroup = true;
        showCreateGroup = false;
        visible = false;
      });
    }
  }

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      searchForGroup();
    }
  }

  const createGroup = async () => {
    await addDoc(collection(db, "groups"), {
      groupCode: searchGroupCode,
      words: [],
      currentDate: null,
      currentWord: null,
      currentWordId: null
    }).then((doc => {
      toaster.pop(`Created group "${searchGroupCode}"`, 3);

      const createdDoc = {
        groupCode: searchGroupCode,
        words: [],
        currentDate: null,
        currentWord: null,
        currentWordId: null,
        id: doc.id
      }
      dispatch("groupSelected", { chosenCode: searchGroupCode, newGroup: true, groupDoc: createdDoc});
      restart();
    }));
  }

  const joinGroup = async () => {
    toaster.pop(`Joined group "${searchGroupCode}"`, 3);
    dispatch("groupSelected", { chosenCode: searchGroupCode, newGroup: false, groupDoc: searchedGroup});
    restart();
  }

  let groupsExceptThisOne: string[] = [];

  onMount(async () => {
    if ($settings.joinedGroups && $settings.joinedGroups.length > 1) {
      groupsExceptThisOne = $settings.joinedGroups.filter((el) => el !== $settings.groupDoc?.groupCode);
    }
  })
</script>

<h3>Choose or make a group</h3>
<div>Enter your <strong>GROUPLE</strong> group code to search for an existing group, or start a new group.</div>

<div class="row">
  <Input uppercase={true} tabindex="0" bind:value={groupCode} placeholder="Group code" on:keypress={handleEnter} />
  <div class="button" on:click={searchForGroup}>Search for group</div>
</div>
{#if msg}
  <div class="row">
    <div class="msg">{msg}</div>
  </div>
  {#if showCreateGroup}
    <h3 class="button" on:click={createGroup}>Create Group "{searchGroupCode}"</h3>
  {/if}
  {#if showJoinGroup}
    <h3 class="button" on:click={joinGroup}>Join "{searchGroupCode}"</h3>
  {/if}
{/if}
{#if !msg && groupsExceptThisOne.length > 0}
  <h3 style="margin-top:20px">Previous Groups</h3>
  <div style="display:flex;justify-content: space-evenly">
    {#each groupsExceptThisOne as group}
      <div class="prevGroup">{group}</div>
    {/each}
  </div>
{/if}

<style lang="scss">
  .msg {
    font-style: italic;
  }
  .button {
    padding: 5px;
    background: var(--border-primary);
    cursor: pointer;
  }
  .row {
    height: 25px;
    width: 100%;
    display: inline-flex;
    gap: 4px;
    margin: auto;
    justify-content: center;
  }
  .prevGroup {
    padding: 5px;
  }
</style>
