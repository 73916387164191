<script lang="ts">
  import {collection, getDocs, getDoc, query, where, addDoc, orderBy, limit} from "firebase/firestore";

  import { db } from "../../firebase";
  import type Toaster from "./Toaster.svelte";
  import { createEventDispatcher, getContext } from "svelte";
  import {settings} from "../../stores";

  const dispatch = createEventDispatcher();

  const toaster = getContext<Toaster>("toaster");

  let scores = [];

  const getScores = async () => {
    const q = query(collection(db, "scores"),
        where("word_id", "==", $settings.groupDoc.currentWordId),
        orderBy("score"), orderBy("hardMode", "desc"), orderBy("submissionTime"), limit(10));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return;
    }
    else {
      // forEach, but expect only one result
      querySnapshot.forEach((doc) => {
        const scoreDoc = doc.data();
        scores.push({
          score: scoreDoc.score,
          hardMode: scoreDoc.hardMode,
          username: scoreDoc.username
        })
      })
      scores.sort((a, b) => { return a.score - b.score })
      scores = scores;
    }
  }
  getScores();
</script>

{#if !scores || scores.length === 0}
    <h4 style="margin-top: 50px; text-align:center;">No scores yet</h4>
{:else}
  <table>
    <thead>
      <tr>
          <th>User</th>
          <th>Score</th>
          <th>Hard mode</th>
      </tr>
    </thead>
    <tbody>
      {#each scores as score}
      <tr>
        <td>{score.username}</td>
        <td>{score.score}</td>
        <td>{score.hardMode ? "✅" : "❌"}</td>
      </tr>
      {/each}
    </tbody>
  </table>
{/if}
<style lang="scss">
  table {
    width: 100%;
  }
  th {
    border-bottom: 1px solid var(--border-primary);
    padding: 3px 0;
  }
  td:last-child {
    border-right: none;
  }
  td {
    border-right: 1px solid var(--border-primary);
    text-align: center;
  }
</style>
