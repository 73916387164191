<script lang="ts">
  export let value: string;
  export let disabled = false;
  export let placeholder: string;
  export let uppercase: boolean = false;
</script>

<input style={uppercase ? "text-transform: uppercase" : undefined} autofocus="autofocus" bind:value {disabled} {placeholder} />

<style>
  input {
    border: solid 1px var(--border-secondary);
    padding: 8px;
    min-width: 85px;
    width: 30%;
    border-radius: 4px;
    cursor: pointer;
    background: var(--bg-secondary);
    color: var(--fg-primary);
  }
</style>
