<script lang="ts">
  import Keyboard from "../keyboard/Keyboard.svelte"
  import {COLS, seededRandomInt, words} from "../../utils";
  import Row from "../board/Row.svelte";
  import {
    addDoc,
    collection,
    updateDoc,
    arrayUnion,
    Timestamp,
    doc as makeDoc,
    query,
    where,
    getDocs
  } from "firebase/firestore";
  import {db} from "../../firebase";
  import { settings } from "../../stores";
  import {createEventDispatcher, getContext} from "svelte";
  import Toaster from "./Toaster.svelte";

  export let chosenWord = "";
  export let newGroupCreation: boolean = false;
  export let score: number | null;
  export let guesses: string[] | null;
  export let scoreId;

  let row: Row;
  let clickedRandom = false;

  const toaster = getContext<Toaster>("toaster");
  const dispatch = createEventDispatcher();

  const submitWord = async (isRandom = false) => {

    if (!words.valid.includes(chosenWord) && !words.words.includes(chosenWord)) {
      toaster.pop("Sorry, that word isn't a valid Wordle", 3);
      row.shake();
      return;
    }

    let dateToUse = new Date();
    if (!newGroupCreation) {
      dateToUse.setDate(new Date().getDate() + 1);
    }
    if (newGroupCreation) {
      // If it's a new group, make that the current word
      await addDoc(collection(db, "words"), {
        groupCode: $settings.groupDoc?.groupCode,
        submittedUser: isRandom ? "Random Wordle" : $settings.username,
        word: chosenWord,
        date: Timestamp.fromDate(dateToUse),
      }).then(async (doc) => {
        $settings.groupDoc.currentWord = chosenWord;
        $settings.groupDoc.currentDate = Timestamp.fromDate(dateToUse);
        $settings.groupDoc.currentWordId = doc.id;
        // And set the group doc to include this word because it's the first
        const groupDoc = makeDoc(db, "groups", $settings.groupDoc.id);
        await updateDoc(groupDoc, {
          currentWord: chosenWord,
          currentDate: Timestamp.fromDate(dateToUse),
          currentWordId: doc.id,
          words: arrayUnion(doc.id)
        }).then(() => {
            toaster.pop("Word submitted!", 3);
            dispatch("wordSubmitted");
          });
        });
    }
    else {
      if (!scoreId) {
        const q = query(collection(db, "scores"),
            where("groupCode", "==", $settings.groupDoc.groupCode),
            where("username", "==", $settings.username),
            where("word_id", "==", $settings.groupDoc.currentWordId)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty){
          debugger;
          return;
        }

        querySnapshot.forEach((scoreDoc) => {
          updateDoc(scoreDoc.ref, {
            nextWord: chosenWord,
            isRandom: isRandom,
            username: $settings.username
          });
        })
        dispatch("wordSubmitted");
      }
      else {
        // Otherwise add the score in to the pile
        const existingScore = makeDoc(db, "scores", scoreId);
        const q = await updateDoc(existingScore, {
          nextWord: chosenWord,
          isRandom: isRandom,
          username: $settings.username
        });
        dispatch("wordSubmitted");
      }
    }
  }
</script>

<div style="text-align: center">
  <h3>
    Submit your word
  </h3>
  <h5>
    {#if newGroupCreation}
      Choose the first word for the new group and it'll be used for today's word.
      <br /><br />If you want to play today, hit <strong>"Random word"</strong>.
      (Otherwise you'll get the word in one guess, you cheater)
    {:else}
      If you end up with the best score for today,
      <br />your word will be chosen for the next day.
      <br /><br />Not feeling creative? Hit "Random word" and one will be chosen for you.
    {/if}
  </h5>
  <div class="choosingWord-board">
    {#if !clickedRandom}
      <Row bind:this={row} num={1} guesses={0} state={new Array(COLS).fill("🔳")} value={chosenWord} />
    {/if}
  </div>
  <div style="display: flex; justify-content: space-between">
    <h4
      class="noWordChosen"
      on:click={() => {
        clickedRandom = true;
        chosenWord = words.words[seededRandomInt(0, words.words.length, Math.random())];
        submitWord(true);
      }}
    >Random word
    </h4>
    {#if chosenWord?.length === 5}
      <h4 class="submitChosenWord" on:click={() => {submitWord()}}>Submit</h4>
    {/if}
  </div>
</div>
<Keyboard bind:value={chosenWord}/>
<style>
.noWordChosen {
    text-align: left;
    padding: 5px;
    background: var(--border-primary);
}
h4 {
  cursor: pointer;
}
.submitChosenWord {
    text-align: right;
    padding: 5px;
    background: var(--border-primary);
}
.choosingWord-board {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    gap: 5.5px;
    height: 70px;
    max-height: 70px;
    flex-grow: 1;
    aspect-ratio: 5;
    padding: 10px;
    position: relative;
    margin: 0 auto;
}
</style>
