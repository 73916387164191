import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAWImJnRC4s60XiOQeFXzltxBo7_ir9dFo",
  authDomain: "grouple-670a5.firebaseapp.com",
  projectId: "grouple-670a5",
  storageBucket: "grouple-670a5.appspot.com",
  messagingSenderId: "557479499902",
  appId: "1:557479499902:web:b88439e04e4f2611d2202f"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const generateAuth = async () => {
  const auth = getAuth();
  return await signInAnonymously(auth)
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
}

export const db = getFirestore(app);
