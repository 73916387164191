<script lang="ts">
	import Header from "./Header.svelte";
	import { Board } from "./board";
	import Keyboard from "./keyboard";
	import GroupCodePrompt from "./widgets/GroupCodePrompt.svelte";
	import Modal from "./Modal.svelte";
	import {createEventDispatcher, getContext, onMount, setContext} from "svelte";
	import Settings from "./settings";
	import {
		Share,
		Seperator,
		Definition,
		Tutorial,
		Statistics,
		Distribution,
		Timer,
		Toaster,
		ShareGame,
		Tips,
	} from "./widgets";
	import {
		contractNum,
		DELAY_INCREMENT,
		PRAISE,
		getState,
		modeData,
		checkHardMode,
		ROWS,
		COLS,
		newSeed,
		createNewGame,
		seededRandomInt,
		createLetterStates,
		words,
	} from "../utils";
	import { letterStates, settings, mode } from "../stores";
	import SubmitWordPrompt from "./widgets/SubmitWordPrompt.svelte";
	import {addDoc, collection, limit, orderBy, query, Timestamp, where} from "firebase/firestore";
	import {db} from "../firebase";
	import GroupStats from "./widgets/GroupStats.svelte";

	export let word: string;
	export let stats: Stats;
	export let game: GameState;
	export let toaster: Toaster;

	setContext("toaster", toaster);
	const version = getContext<string>("version");

	const dispatch = createEventDispatcher();

	// implement transition delay on keys
	const delay = DELAY_INCREMENT * ROWS + 800;

	let showTutorial = $settings.tutorial === 3;
	let showSettings = false;
	let showStats = false;
	let showRefresh = false;

	let board: Board;
	let timer: Timer;
	let tips: Tips;

	let params = (new URL(document.location)).searchParams;
	let groupCode: string = params.get("group");

	if (!$settings.joinedGroups) {
		$settings.joinedGroups = [];
	}

	if (groupCode) {
		$settings.groupDoc.groupCode = groupCode;
		if (!$settings.joinedGroups.includes(groupCode)) {
			$settings.joinedGroups.push(groupCode);
		}
	}

	let choosingWord = false;
	let newGroupCreated = false;
	let settingsPage: "Yours" | "Group" = "Yours";

	let showGroupCodeModal = !$settings.groupDoc?.groupCode;
	if (showGroupCodeModal) {
		showTutorial = false;
	}
	let createdScore;

	let tip = 0;
	$: if (showSettings && tips) tip = Math.floor(tips.length * Math.random());

	function submitWord() {
		if (game.board.words[game.guesses].length !== COLS) {
			toaster.pop("Not enough letters");
			board.shake(game.guesses);
		} else if (words.contains(game.board.words[game.guesses])) {
			if (game.guesses > 0) {
				const hm = checkHardMode(game.board, game.guesses);
				if ($settings.hard) {
					if (hm.type === "🟩") {
						toaster.pop(
							`${contractNum(hm.pos + 1)} letter must be ${hm.char.toUpperCase()}`
						);
						board.shake(game.guesses);
						return;
					} else if (hm.type === "🟨") {
						toaster.pop(`Guess must contain ${hm.char.toUpperCase()}`);
						board.shake(game.guesses);
						return;
					}
				} else if (hm.type !== "⬛") {
					game.validHard = false;
				}
			}
			const state = getState(word, game.board.words[game.guesses]);
			game.board.state[game.guesses] = state;
			state.forEach((e, i) => {
				const ls = $letterStates[game.board.words[game.guesses][i]];
				if (ls === "🔳" || e === "🟩") {
					$letterStates[game.board.words[game.guesses][i]] = e;
				}
			});
			++game.guesses;
			if (game.board.words[game.guesses - 1] === word) win();
			else if (game.guesses === ROWS) lose();
		} else {
			toaster.pop("Not in word list");
			board.shake(game.guesses);
		}
	}

	async function win() {
		board.bounce(game.guesses - 1);
		setTimeout(
			() => toaster.pop(PRAISE[game.guesses - 1]),
			DELAY_INCREMENT * COLS + DELAY_INCREMENT
		);
		setTimeout(() => (showStats = true), delay * 1.4);
		if (!modeData.modes[$mode].historical) {
			++stats.guesses[game.guesses];
			++stats.played;
			if ("streak" in stats) {
				stats.streak =
					modeData.modes[$mode].seed - stats.lastGame > modeData.modes[$mode].unit
						? 1
						: stats.streak + 1;
				if (stats.streak > stats.maxStreak) stats.maxStreak = stats.streak;
			}
			stats.lastGame = modeData.modes[$mode].seed;
			localStorage.setItem(`stats-${$mode}`, JSON.stringify(stats));
		}
		setTimeout(() => { game.active = false; addScore() }, delay * 2.5);
	}

	async function lose() {
		setTimeout(() => (showStats = true), delay);
		if (!modeData.modes[$mode].historical) {
			++stats.guesses.fail;
			++stats.played;
			if ("streak" in stats) stats.streak = 0;
			stats.lastGame = modeData.modes[$mode].seed;
			localStorage.setItem(`stats-${$mode}`, JSON.stringify(stats));
		}
		setTimeout(() => { game.active = false; addScore() }, delay * 2.5);
	}

	const addScore = async (lost: boolean = false) => {
		await addDoc(collection(db, "scores"), {
			groupCode: $settings.groupDoc?.groupCode,
			word_id: $settings.groupDoc.currentWordId,
			username: $settings.username,
			score: game.guesses + (lost ? 1 : 0),
			hardMode: $settings.hard,
			submissionTime: Timestamp.fromDate(new Date()),
			guesses: game.board.words,
		}).then((doc) => {
			createdScore = doc.id
		});
		$letterStates = createLetterStates();
	}

	function concede() {
		showSettings = false;
		setTimeout(() => (showStats = true), DELAY_INCREMENT);
		lose();
	}

	function reload() {
		modeData.modes[$mode].historical = false;
		modeData.modes[$mode].seed = newSeed($mode);
		game = createNewGame($mode);
		$letterStates = createLetterStates();
		showStats = false;
		showRefresh = false;
		timer.reset($mode);
		dispatch("reloadGroup");
	}

	const groupSelected = (event) => {
		showGroupCodeModal = false;
		$settings.groupDoc = event.detail.groupDoc;
		if (!$settings.joinedGroups.includes(event.detail.groupDoc)) {
			$settings.joinedGroups.push(event.detail.groupDoc.groupCode);
		}
		if (event.detail.newGroup) {
			newGroupCreated = true;
		}
		showTutorial = $settings.tutorial === 3;
		reload();
	}

	const fakeBoard = {
		words: Array(1).fill(""),
		state: Array.from({ length: 1 }, () => (Array(COLS).fill("🔳")))
	};

	onMount(() => {
		if (!game.active && game.submittedWord) setTimeout(() => (showStats = true), delay);
	});
	// $: toaster.pop(word);
</script>

<svelte:body on:click={() => { game.active && board?.hideCtx() }} on:contextmenu={board?.hideCtx()} />

<main class:guesses={game.guesses !== 0} style="--rows: {ROWS}; --cols: {COLS}">
	<Header
		bind:showRefresh
		tutorial={$settings.tutorial === 2}
		on:closeTutPopUp|once={() => ($settings.tutorial = 1)}
		showStats={stats.played > 0 || !game.active}
		on:stats={() => (showStats = true)}
		on:tutorial={() => (showTutorial = true)}
		on:settings={() => (showSettings = true)}
		on:reload={reload}
		on:promptGroupCodeModal={() => (showGroupCodeModal = true)}
		groupCode={$settings.groupDoc?.groupCode}
	/>
	{#if newGroupCreated || (!game.active && !game.submittedWord && !showStats)}
		<SubmitWordPrompt
			on:wordSubmitted={() => {
				choosingWord = false;
				if (newGroupCreated) {
					dispatch("reloadGroup")
				} else {
					game.submittedWord = true;
				}
				newGroupCreated = false;
			}}
			newGroupCreation={newGroupCreated}
			scoreId={createdScore}
			guesses={game.board.words}
		/>
	{:else}
		<Board
			bind:this={board}
			bind:value={game.board.words}
			tutorial={$settings.tutorial === 1}
			on:closeTutPopUp|once={() => ($settings.tutorial = 0)}
			board={game.board}
			guesses={game.guesses}
			icon={modeData.modes[$mode].icon}
		/>
	<Keyboard
		on:keystroke={() => {
			if ($settings.tutorial) $settings.tutorial = 0;
			board.hideCtx();
		}}
		bind:value={game.board.words[game.guesses === ROWS ? 0 : game.guesses]}
		on:submitWord={submitWord}
		on:esc={() => {
			showTutorial = false;
			showStats = false;
			showSettings = false;
		}}
		disabled={!game.active || showTutorial || showGroupCodeModal}
	/>
	{/if}
</main>

<Modal
	bind:visible={showTutorial}
	on:close|once={() => $settings.tutorial === 3 && --$settings.tutorial}
	fullscreen={false}
>
	<Tutorial visible={showTutorial} on:closeTutorial={() => {showTutorial = false; $settings.tutorial = 0;}} />
</Modal>

<Modal bind:visible={showGroupCodeModal} on:close={() => {
	if (!$settings.groupDoc?.groupCode) {
		showGroupCodeModal = true
		toaster.pop("You must choose or make a group to play Grouple!", 5);
	}
}}>
	<GroupCodePrompt
		visible={showGroupCodeModal}
		on:groupSelected={groupSelected}
	/>
</Modal>

<Modal bind:visible={showStats}>
	<div class="stats-choice">
		<h3 class={settingsPage === "Yours" ? "active" : undefined} on:click={() => (settingsPage = "Yours")}>Your stats</h3>
		<h3 class={settingsPage !== "Yours" ? "active" : undefined} on:click={() => (settingsPage = "Group")}>Group stats</h3>
	</div>
	{#if settingsPage === "Yours"}
		{#if modeData.modes[$mode].historical}
			<h2 class="historical">Statistics not available for historical games</h2>
		{:else}
			<Statistics data={stats} />
			<Distribution distribution={stats.guesses} guesses={game.guesses} active={game.active} />
		{/if}
		<Seperator visible={!game.active}>
			<Timer
				slot="1"
				bind:this={timer}
				on:timeup={() => (showRefresh = true)}
				on:reload={reload}
			/>
			<Share slot="2" state={game} />
		</Seperator>
		<ShareGame wordNumber={game.wordNumber} />
		{#if !game.active}
			<Definition {word} alternates={2} />
		{/if}
	{:else}
			<h3 style="display: flex; justify-content: center">{$settings.groupDoc?.groupCode} Scores</h3>
			<GroupStats />
	{/if}

</Modal>

<Modal fullscreen={true} bind:visible={showSettings}>
	<Settings state={game} on:promptGroupCodeModal={() => { showSettings = false; showGroupCodeModal = true }} />
	{#if game.active}
		<div class="concede" on:click={concede}>give up</div>
	{/if}
	<Tips bind:this={tips} index={tip} />

	<div slot="footer">
		<a href="https://www.powerlanguage.co.uk/wordle/" target="_blank">Original Wordle</a>
		<div>
			<div>v{version}</div>
			<div
				title="double click to reset your stats"
				class="word"
				on:dblclick={() => {
					localStorage.clear();
					toaster.pop("localStorage cleared");
				}}
			>
				{modeData.modes[$mode].name} word #{game.wordNumber}
			</div>
		</div>
	</div>
</Modal>

<style lang="scss">
	main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		max-width: var(--game-width);
		margin: auto;
		position: relative;
	}
	.historical {
		text-align: center;
		margin-top: 10px;
		padding: 0 20px;
		text-transform: uppercase;
	}
	.concede {
		margin-top: 15px;
		text-transform: uppercase;
		color: #fff;
		cursor: pointer;
		font-size: var(--fs-medium);
		font-weight: bold;
		padding: 15px;
		border-radius: 4px;
		text-align: center;
		background-color: var(--red);
		&:hover {
			opacity: 0.9;
		}
	}
	.stats-choice {
		display: flex;
		margin-left: 30px;

		h3 {
			color: var(--fg-secondary);
			font-weight: 500;
			cursor: pointer;
			margin-right: 30px;
		}
		h3.active {
			text-decoration: underline;
			font-weight: 700;
			color: var(--fg-primary);
		}
	}
</style>
