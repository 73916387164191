<script lang="ts">
	import { COLS, ROWS } from "../../utils";

	import { Tile } from "../board";
	import {settings} from "../../stores";
	import Toaster from "./Toaster.svelte";
	import {createEventDispatcher, getContext} from "svelte";

	const toaster: Toaster = getContext("toaster");
	const dispatch = createEventDispatcher();
	export let visible: boolean;
</script>

<h3>how to play</h3>
<div><strong>Grouple</strong> is <a href="https://www.nytimes.com/games/wordle/index.html">Wordle</a> with friends!</div>
<div>If you get the best score on today's Grouple, you can choose the word for tomorrow's Grouple (or submit a random word so you can play tomorrow).</div>
{#if $settings.groupDoc?.groupCode}
	<div>You're currently in group <strong>{$settings.groupDoc.groupCode}</strong>, but you can also create your own groups.</div>
{:else}
	<div>You can create as many groups as you'd like - switch between them by using the settings menu or tapping the GROUP name in the top left.</div>
{/if}
	<h4 style="text-align: center;">What name would you like to go by?</h4>
<div style="display: flex; justify-content: center;">
	<input bind:value={$settings.username} />
	<button on:click={() => { toaster.pop("Username saved!", 3); dispatch("closeTutorial")}}>Submit</button>
</div>

<style lang="scss">
	div {
		margin: 14px 0;
	}
	.examples {
		border-top: 1px solid var(--border-primary);
		border-bottom: 1px solid var(--border-primary);
		:global(.row > *) {
			height: 100%;
			aspect-ratio: 1;
		}
		&:not(.complete) :global(.row .back) {
			transition-delay: 0.3s;
		}
	}
	button {
		padding: 0 10px;
		font-weight: 700;
	}
	.row {
		height: 40px;
		display: flex;
		gap: 4px;
	}
</style>
